import React from "react";
import AOS from "aos";

// styles. 
import "./src/styles/global.css";
import "aos/dist/aos.css";

// contexts.
import { PopupProvider } from "@/contexts/PopUp";
import { PageProvider } from "@/contexts/Page";

export const onClientEntry = () => {
  AOS.init({
    duration: 1200,
  });
};

export const onRouteUpdate = () => {
  AOS.refresh();
};

export const wrapRootElement = ({ element }) => (
  <PageProvider>
    <PopupProvider>{element}</PopupProvider>
  </PageProvider>
);
