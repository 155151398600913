exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-chef-tsx": () => import("./../../../src/pages/chef.tsx" /* webpackChunkName: "component---src-pages-chef-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/Event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/Location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

